import React, { Component } from "react";
import { Dialog, Popup } from "../../../components/Popup";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import UserForm from "./Form";
import { getMessage } from "../../../lib/translator";
import "./style.css";
import ResetPasswordForm from "../../hr/UserShifts/Form/ResetPassword";
import Image from "../../../components/Image";

import emptyIcon from "./user-empty.svg";
import userImage from "../userImage.svg";
import UserFiltersForm from "./Filter";

const emptyState = {
  icon: emptyIcon,
  message: getMessage("sellers.user.add.nouser.text"),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD);
      }}
    >
      + {getMessage("sellers.user.add.text")}
    </button>
  ),
};

class UsersActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReset: false,
      showSuccess: false,
    };
  }
  render() {
    const { onAction, id, name } = this.props;
    const { showReset, showSuccess } = this.state;
    return (
      <React.Fragment>
        {showSuccess && (
          <Dialog
            className="success"
            show={this.state.showSuccess}
            title={getMessage("users.reset.success.title")}
            information={getMessage("users.reset.success.information")}
            closeText={getMessage("users.reset")}
            close={() => this.setState({ showSuccess: false })}
          />
        )}
        {showReset && (
          <Popup
            className="reset-password"
            show={this.state.showReset}
            heading={`${getMessage("users.reset.title")} ${name}`}
            close={() => this.setState({ showReset: false })}
          >
            <ResetPasswordForm
              id={id}
              onSuccess={() =>
                this.setState({ showReset: false, showSuccess: true })
              }
              close={() => this.setState({ showReset: false })}
            />
          </Popup>
        )}
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}>
            {getMessage("user.action.edit")}
          </DropDownItem>

          <DropDownItem onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}>
            {getMessage("user.action.delete")}
          </DropDownItem>

          <DropDownItem onClick={() => this.setState({ showReset: true })}>
            {getMessage("user.action.reset.password")}
          </DropDownItem>
        </DropDown>
      </React.Fragment>
    );
  }
}

const tableProperties = () => {
  return {
    headers: [
      getMessage("sellers.user.table.name"),
      getMessage("sellers.user.table.sellername"),
      getMessage("sellers.user.table.email"),
      getMessage("sellers.user.table.action"),
    ],
    row: ({ id, name, seller, email, onAction }) => (
      <Row>
        <Cell className="user-profile">
          <Image src={userImage} size="sm" />
          <div className="user-details">
            <div
              className="user-name"
              onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}
            >
              {name}
            </div>
          </div>
        </Cell>
        <Cell className="seller-name">
          <small>{seller?.name}</small>
        </Cell>
        <Cell className="email-address">
          <small>{email}</small>
        </Cell>
        <Cell className="user-actions">
          <UsersActions id={id} onAction={onAction} name={name} />
        </Cell>
      </Row>
    ),
  };
};

export default class SellerUsers extends Component {
  render() {
    let props = this.props;
    return (
      <ListingPage
        menu={props.menu}
        className="users-page"
        addHeading={getMessage("sellers.user.add.text")}
        editHeading={getMessage("seller.user.edit.text")}
        title="Users"
        api={{
          url: "/account-service/employee",
          params: { isSeller: true },
          transform: (response) => {
            let updatedResponse;
            const { employee } = response.data;
            if (Array.isArray(employee)) {
              updatedResponse = employee?.map((employ) => {
                const updatedEmployee = {
                  ...employ,
                  email: employ?.emails?.[0]?.email,
                  phone: employ?.phones?.[0]?.phone,
                };
                delete updatedEmployee.emails;
                delete updatedEmployee.phones;
                return updatedEmployee;
              });
            } else {
              updatedResponse = {
                ...employee,
                email: employee?.emails?.[0]?.email,
                phone: employee?.phones?.[0]?.phone,
              };
              delete updatedResponse.emails;
              delete updatedResponse.phones;
            }
            return updatedResponse;
          },
        }}
        emptyState={emptyState}
        tableProperties={tableProperties()}
        headerActions={({ onAction }) => (
          <button
            className="primary button"
            onClick={() => {
              onAction(TABLE_ACTIONS.ADD);
            }}
          >
            +{" "}
            <span className="text">{getMessage("sellers.user.add.text")}</span>
          </button>
        )}
        form={{
          component: UserForm,
          allowDelete: true,
          transformSubmit: (formData) => {
            let { name, email, phone, seller } = formData;
            return {
              name,
              phone,
              email,
              sellerID: seller?.id,
              hasToolAccess: 1,
            };
          },
        }}
        filters={{
          component: UserFiltersForm,
          transformSubmit: (formData) => {
            let filters = Object.assign({}, formData);
            if (formData.name) {
              filters.name = formData.name.name;
            }
            if (formData.email) {
              filters.email = formData.email?.emails?.[0]?.email;
            }
            return filters;
          },
        }}
      />
    );
  }
}
