import React from "react";
import { BaseForm, Radio, Searchable } from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";

// import "./style.css";

export default class UserFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;

    return (
      <Form className="seller-filter-form">
        <div className="form-fields">
          <Searchable
            label={getMessage("seller.filters.sellerName.heading")}
            placeholder={getMessage(
              "seller.filters.permissableSeller.placeholder"
            )}
            name="email"
            searchUrl="/account-service/employee"
            valueKey="id"
            nameKey="name"
            searchKey="email"
            transformRequest={(searchText, paramsDefault) => {
              let params = Object.assign({}, paramsDefault);
              params.isSeller = true;

              return params;
            }}
            transformResponse={(response) => response.data.employee}
            {...this.generateStateMappers({
              stateKeys: ["email"],
            })}
          />
          <Searchable
            label={getMessage("brand.filters.name.heading")}
            placeholder={getMessage("category.filters.name.placeholder")}
            name="name"
            searchUrl="/account-service/employee"
            valueKey="id"
            nameKey="name"
            searchKey="name"
            transformRequest={(searchText, paramsDefault) => {
              let params = Object.assign({}, paramsDefault);
              params.isSeller = true;

              return params;
            }}
            transformResponse={(response) => response?.data?.employee}
            {...this.generateStateMappers({
              stateKeys: ["name"],
            })}
          />
          <Radio
            className="product-status"
            label={getMessage("product.filters.status.heading")}
            name="status"
            options={[
              {
                text: getMessage("product.filters.status.label.all"),
                value: "",
              },
              {
                text: getMessage("product.filters.status.label.enabled"),
                value: "ACTIVE",
              },
              {
                text: getMessage("product.filters.status.label.disabled"),
                value: "INACTIVE",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              defaultValue: "",
            })}
          />
        </div>
        <SubmitButton>{getMessage("product.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("product.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
